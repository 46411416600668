import { useEffect, useMemo, useState } from 'react';
import { useAsync } from 'react-use';
import {
  ChatActionsResponse,
  ChatSessionResponse,
  ChatSessionsResponse,
  ChatSettingsResponse,
} from '@worknet/models';
import { Box, Flex, Icon } from '@chakra-ui/react';

import { HostPlatformContext } from '~/shared/utils/api';
import { ReactComponent as ChatIcon } from '~/shared/components/ds2/assets/Chat.svg';
import { ReactComponent as ClockIcon } from '~/shared/components/ds2/assets/Clock.svg';
import MainFlowLayout from '~/shared/components/ds/MainFlowLlayout';
import BouncingLoader from '~/shared/components/ds/BouncingLoader';
import useErrorHandler from '~/shared/utils/useErrorHandler';

import { getUniqueUsersImages } from '../utils';
import Messages, { MessagesEvent } from '../Messages';
import Sessions, { SessionsEvent } from '../Sessions';

import Header from './Header';
import UsersImages from './UsersImages';

interface SwitcherProps {
  hostPlatformContext: HostPlatformContext;
  chatSettings: ChatSettingsResponse;
}

export default function Switcher({ hostPlatformContext, chatSettings }: SwitcherProps) {
  const { user, groupingId, apiFn } = hostPlatformContext;

  const [selectedSessionId, setSelectedSessionId] = useState<string | undefined>();
  const [isNewChat, setIsNewChat] = useState(groupingId && user ? false : true);
  const [isNewButtonDisabled, setIsNewButtonDisabled] = useState(false);

  const {
    value: sessions,
    loading: isLoadingSessions,
    error: sessionsError,
  } = useAsync(async () => {
    if (selectedSessionId !== undefined || !groupingId || !user) {
      return undefined;
    }
    const startAtDaysAgo = 14;
    const qs = new URLSearchParams({
      groupingId: groupingId ?? '',
      startAt: new Date(Date.now() - startAtDaysAgo * 24 * 60 * 60 * 1000).toISOString(),
    });
    return apiFn<ChatSessionsResponse>(`/chats?${qs.toString()}`);
  }, [selectedSessionId, apiFn]);

  const { error: promptsError, value: prompts } = useAsync(async () => {
    return apiFn<ChatActionsResponse>('/chat-actions');
  }, [apiFn]);

  const {
    loading: isLoadingInitialMessages,
    error: initialMessagesError,
    value: initialMessages,
  } = useAsync(async () => {
    if (!selectedSessionId) {
      return undefined;
    }
    return apiFn<ChatSessionResponse>(`/chats/${selectedSessionId}`);
  }, [apiFn, selectedSessionId]);

  useEffect(() => {
    sessions?.length === 0 && setIsNewChat(true);
  }, [sessions]);

  useErrorHandler(promptsError || initialMessagesError || sessionsError);

  const openChat = (sessionsId?: string) => {
    setIsNewChat(!sessionsId);
    setSelectedSessionId(sessionsId);
  };

  const onSessionsEvent = (event: SessionsEvent) => {
    if (event.name === 'openChat') {
      openChat(event.sessionsId);
    }
  };

  const onMessagesEvent = (event: MessagesEvent) => {
    if (event.name === 'fetchStart') {
      setIsNewChat(false);
      setIsNewButtonDisabled(true);
    } else if (event.name === 'fetchEnd') {
      setIsNewButtonDisabled(false);
    }
  };

  const showSessions =
    selectedSessionId === undefined &&
    hostPlatformContext.groupingId &&
    user &&
    (sessions ? sessions.length > 0 : true);

  const uniqueUsersImages = useMemo(() => {
    return sessions ? getUniqueUsersImages(sessions) : [];
  }, [sessions]);
  const displayedUsersImages = uniqueUsersImages?.slice(0, 3) || undefined;

  const customMenuItems = [
    ...(showSessions
      ? []
      : hostPlatformContext.groupingId
      ? [
          {
            text: 'Conversation History',
            IconSvg: ClockIcon,
            onClick: () => setSelectedSessionId(undefined),
            isDisabled: sessions?.length === 0,
          },
        ]
      : []),
  ];

  const formattedInitialMessages = useMemo(
    () =>
      initialMessages?.messages.map(({ role, messageId, chatId, content, user, createdAt }) => ({
        role,
        messageId,
        chatId,
        content,
        actionId: '',
        user,
        createdAt: new Date(createdAt),
      })),
    [initialMessages]
  );

  if (
    (showSessions && (isLoadingSessions || sessionsError)) ||
    !prompts ||
    (!showSessions && (isLoadingInitialMessages || initialMessagesError))
  ) {
    return (
      <MainFlowLayout>
        <BouncingLoader />
      </MainFlowLayout>
    );
  }

  return (
    <MainFlowLayout px={showSessions ? '0px' : '21px'}>
      <Header
        px={showSessions ? '21px' : '0px'}
        user={user}
        newButtonProps={{
          onClick: () => openChat(''),
          isDisabled: isNewChat || isNewButtonDisabled,
        }}
        customMenuItems={customMenuItems}
        customHeader={
          showSessions && sessions ? (
            <Flex gap="8px">
              <Flex gap="5px" align="center">
                <Icon boxSize="22px" fill="currentColor" as={ChatIcon} />
                {sessions.length || 0}
              </Flex>

              <Flex gap="5px" align="center">
                <UsersImages
                  imagesSrc={displayedUsersImages}
                  w={`${22 + (displayedUsersImages.length - 1) * 13}px`}
                />

                {uniqueUsersImages.length || 0}
              </Flex>
            </Flex>
          ) : undefined
        }
      />
      {showSessions && sessions ? (
        <Sessions sessions={sessions} onSessionsEvent={onSessionsEvent} />
      ) : (
        <Box h="calc(100% - 70px)">
          <Messages
            hostPlatformContext={hostPlatformContext}
            prompts={prompts}
            chatSettings={chatSettings}
            isNewChat={isNewChat}
            initialMessages={formattedInitialMessages || []}
            onMessagesEvent={onMessagesEvent}
          />
        </Box>
      )}
    </MainFlowLayout>
  );
}
