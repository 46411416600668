import { useRef, useEffect } from 'react';

export default function ScrollPoint({ active }: { active: boolean }) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!active) {
      return;
    }
    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    });
  });
  return <div ref={ref}></div>;
}
