import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  ModalFooter,
  Button,
} from '@chakra-ui/react';

export default function TextboxModal({
  button,
  prompt,
  onClose,
}: {
  button: string;
  prompt: string;
  onClose: (ok: boolean) => void;
}) {
  const { isOpen, onClose: closeModal } = useDisclosure({ isOpen: true });

  function onModalFinished(ok?: boolean) {
    closeModal();
    onClose(ok || false);
  }

  return (
    <Modal isOpen={isOpen} onClose={onModalFinished}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{prompt}</Text>
        </ModalBody>
        <ModalFooter>
          <Button background="main" color="white" onClick={() => onModalFinished(true)}>
            {button}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
