import { useAuth0 } from '@auth0/auth0-react';
import { Button, Center, Image, Text } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import MainFlowLayout from '~/shared/components/ds/MainFlowLlayout';
import ChatScreenShot from '~/shared/components/ds/assets/chat-screen-shot.jpg';

export default function AuthenticatedOutlet() {
  const { user, loginWithPopup, error } = useAuth0();
  const qs = new URLSearchParams(location.search);
  const errorFromParams = qs.get('error');

  async function onLoginClick() {
    await loginWithPopup({ authorizationParams: { prompt: 'login' } });
    removeErrorUrlParam();
  }

  sessionStorage.setItem('__last_pathname__', window.location.pathname);

  const errorMessage =
    error?.message && !error?.message.includes('user does not belong')
      ? error.message
      : errorFromParams === 'Unauthorized' || error?.message
      ? 'Organization or user is not authorized. Try to login with the correct User and organization. If the problem persists, please contact your administrator.'
      : '';

  if (!user) {
    return (
      <MainFlowLayout>
        <Center mt="94px" flexDir="column">
          <Text fontWeight="700" fontSize="18px" textAlign="center" mb="8px">
            Please login Worknet first
          </Text>
          <Text
            fontSize="chatSmallText"
            color="greyDark"
            lineHeight="20px"
            textAlign="center"
            mb="14px"
            maxW="223px"
          >
            Upon your initial login, the system will facilitate automatic sign-in for subsequent
            sessions.
          </Text>
          <Button variant="primary" w="112px" onClick={onLoginClick}>
            Login now
          </Button>
          <Image mt="52px" src={ChatScreenShot} />
          {errorMessage && <Text color="red">Error: {errorMessage}</Text>}
        </Center>
      </MainFlowLayout>
    );
  }

  return (
    <>
      <Outlet />
    </>
  );
}

function removeErrorUrlParam() {
  const url = new URL(window.location.href);
  url.searchParams.delete('error');
  window.history.replaceState({}, '', url.toString());
}
