import { ChatSettingsResponse } from '@worknet/models';
import { useAsync } from 'react-use';

import { HostPlatformContext } from '~/shared/utils/api';
import MainFlowLayout from '~/shared/components/ds/MainFlowLlayout';
import useErrorHandler from '~/shared/utils/useErrorHandler';

import BouncingLoader from '../ds/BouncingLoader';

import Switcher from './Switcher';

interface HostedAppProps {
  hostedPlatformContext?: HostPlatformContext;
}

export default function HostedApp({ hostedPlatformContext }: HostedAppProps) {
  const { value: chatSettings, error: chatSettingsError } = useAsync(
    async () =>
      hostedPlatformContext
        ? hostedPlatformContext.apiFn<ChatSettingsResponse>('/chat-settings')
        : undefined,
    [hostedPlatformContext]
  );

  useErrorHandler(chatSettingsError);

  if (!hostedPlatformContext || !chatSettings) {
    return (
      <MainFlowLayout>
        <BouncingLoader />
      </MainFlowLayout>
    );
  }

  return (
    <Switcher
      hostPlatformContext={
        chatSettings.groupingSupported
          ? hostedPlatformContext
          : { ...hostedPlatformContext, groupingId: undefined }
      }
      chatSettings={chatSettings}
    />
  );
}
