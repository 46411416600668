import { Box } from '@chakra-ui/react';

import Markdown from './Markdown';

type Props = {
  message: string;
  isSameUser?: boolean;
};

export default function UserMessage({ message, isSameUser }: Props) {
  return (
    <Box
      display="flex"
      alignItems="center"
      border="1px solid transparent"
      fontSize="chatText"
      fontWeight={300}
      lineHeight={1.357}
      borderRadius="8px"
      padding="10px 16px"
      backgroundColor={isSameUser ? 'main' : '#F2F2F2'}
      color={isSameUser ? 'white' : 'black'}
    >
      <Markdown content={message} />
    </Box>
  );
}
