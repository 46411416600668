import { useToast, Stack, keyframes, Box } from '@chakra-ui/react';
import { ComponentProps, useState, useEffect } from 'react';
import {
  ActionDisplayInfo,
  ChatActionsResponse,
  ChatMessageFeedbackRequest,
  ChatMessageFeedbackResponse,
  AnswerSource,
  ChatUserReference,
  MessageUser,
  ChatSettings,
  Citation,
} from '@worknet/models';

import useErrorHandler from '~/shared/utils/useErrorHandler';

import { HostPlatformContext } from '../../../utils/api';
import { TaskRequest, usePolledStreaming } from '../../../utils/usePolledStreaming';
import ServerMessage from '../../ds/ServerMessage';
import UserInput from '../../ds/UserInput';
import UserMessage from '../../ds/UserMessage';
import TextboxModal from '../../ds/TextboxModal';
import { copyToClipboard } from '../../../utils/copyToClipboard';
import ServerLoadingMessage from '../../ds/ServerLoadingMessage';
import ConfirmationModal from '../../ds/ConfirmationModal';
import { createTagGroups } from '../utils';

import MessageContainer from './MessageContainer';
import DefaultMessage from './DefaultMessage';
import ScrollPoint from './ScrollPoint';
import { formatMessageWithHtml, getCitationsButtonsData } from './utils';
import CitationsButtons from './CitationsButtons';

export type AgentChatMessage = {
  role: 'agent';
  sources?: AnswerSource[];
  citations?: Citation[];
  users?: ChatUserReference[];
  followups?: string[];
  liked?: 'pending' | boolean;
  content: string;
  actionId: string;
  chatId: string;
  messageId: string;
  createdAt: Date;
};

type UserChatMessage = {
  role: 'user';
  user?: MessageUser;
} & Pick<AgentChatMessage, 'actionId' | 'content' | 'createdAt'>;

export type ChatMessage = UserChatMessage | AgentChatMessage;

type SubmitType = Parameters<NonNullable<ComponentProps<typeof ServerMessage>['onSubmit']>>[0];

type ModelConfig = Omit<ComponentProps<typeof TextboxModal<ActionDisplayInfo>>, 'onClose'>;
type ConfirmationModelConfig = ComponentProps<typeof ConfirmationModal>;

export type MessagesEvent = { name: 'fetchStart' | 'fetchEnd' };

interface MessagesProps {
  hostPlatformContext: HostPlatformContext;
  prompts: ChatActionsResponse;
  initialMessages: (UserChatMessage | AgentChatMessage)[];
  isNewChat: boolean;
  chatSettings: ChatSettings;
  onMessagesEvent?: (evt: MessagesEvent) => void;
}

const fadeIn = keyframes`
  0% { opacity: 0;}
  100% { opacity: 1;}
`;

function updateLikedStatus(
  messages: ChatMessage[],
  message: AgentChatMessage,
  liked: AgentChatMessage['liked']
) {
  return messages.map((m) => {
    if (m.role === 'agent' && m.messageId == message.messageId) {
      return { ...m, liked };
    }
    return m;
  });
}

export default function Messages({
  hostPlatformContext,
  prompts: { amaAction, actions },
  chatSettings,
  initialMessages,
  isNewChat,
  onMessagesEvent,
}: MessagesProps) {
  const [messages, setMessages] = useState<ChatMessage[]>(initialMessages);
  const [messagesOpacity, setMessagesOpacity] = useState<number>(1); //for animation

  const [interactionLoading, setInteractionLoading] = useState(false);
  const [needToScrollToBottom, setNeedToScrollToBottom] = useState(false);
  const [modalConfig, setCurrentModalConfig] = useState<ModelConfig>();
  const [confirmationModalConfig, setConfirmationModalConfig] = useState<ConfirmationModelConfig>();

  const { user: auth0User } = hostPlatformContext;
  const user: MessageUser | undefined = auth0User
    ? {
        id: auth0User.sub!,
        name: auth0User.name!,
        email: auth0User.email!,
        picture: auth0User.picture!,
      }
    : undefined;
  const toast = useToast();

  const { loading, doFetch, error, data, streaming, taskRequest } =
    usePolledStreaming(hostPlatformContext);

  const fetchWithSendingEvent = async (taskRequest: TaskRequest, chatId?: string) => {
    onMessagesEvent?.({ name: 'fetchStart' });
    await doFetch(taskRequest, chatId).finally(() => onMessagesEvent?.({ name: 'fetchEnd' }));
  };

  useEffect(() => {
    if (isNewChat) {
      setMessagesOpacity(0);
      setTimeout(() => {
        setMessages([]);
        setMessagesOpacity(1);
      }, 600);
    }
  }, [isNewChat, setMessages]);

  useEffect(() => {
    if (data && taskRequest) {
      setMessages((prev) => [
        ...prev,
        {
          role: 'agent',
          actionId: taskRequest.actionId,
          chatId: data.chatId,
          messageId: data.messageId,
          content: data.content,
          sources: data.sources,
          citations: data.citations,
          users: data.users,
          followups: data.followups,
          createdAt: new Date(),
        },
      ]);
    }
  }, [data, taskRequest]);

  useErrorHandler(error);

  function sendAutoPromptMessage(request: Parameters<typeof doFetch>[0]) {
    const action = actions.find((p) => p.id === request.actionId);
    if (!action) {
      return;
    }
    setNeedToScrollToBottom(true);
    setMessages((prev) => [
      ...prev,
      {
        role: 'user',
        actionId: action.id,
        content: action.displayName,
        createdAt: new Date(),
        user,
      },
    ]);
    fetchWithSendingEvent(request);
  }

  function onTextboxModalClose(action: ActionDisplayInfo, text?: string) {
    setCurrentModalConfig(undefined);
    if (!text) {
      return;
    }
    sendAutoPromptMessage({
      actionId: action.id,
      content: text,
    });
  }

  function onAutoPromptSubmit(action: ActionDisplayInfo) {
    const actionId = action.id;

    if (action.userInputRequired) {
      setCurrentModalConfig({
        data: action,
        button: 'Submit',
        placeholder: 'Enter text',
        title: `Title of ${action.displayName}`,
        required: true,
      });
      return;
    }

    const handler = () => {
      sendAutoPromptMessage({ actionId, content: action.displayName });
    };

    if (messages.length > 0) {
      setConfirmationModalConfig({
        prompt: 'Are you sure you want to continue? This action will reset all your messages',
        button: 'Continue',
        onClose: (confirmed) => {
          setConfirmationModalConfig(undefined);
          if (!confirmed) {
            return;
          }
          setMessages([]);
          handler();
        },
      });
    } else {
      handler();
    }
  }

  function onUserInputSubmit(text: string) {
    setNeedToScrollToBottom(true);
    const lastAnswer = messages.findLast((m) => m.role === 'agent') as AgentChatMessage | undefined;
    setMessages((prev) => [
      ...prev,
      {
        role: 'user',
        actionId: amaAction.id,
        content: text,
        user,
        createdAt: new Date(),
      },
    ]);
    fetchWithSendingEvent({ actionId: amaAction.id, content: text }, lastAnswer?.chatId);
  }

  const onServerMessageCommand = async (
    type: SubmitType,
    value: string,
    msg: ChatMessage,
    args: Record<string, string>
  ) => {
    if (msg.role !== 'agent') {
      return;
    }
    try {
      if (type === 'button') {
        switch (value) {
          case 'dislike':
          case 'like':
            {
              setNeedToScrollToBottom(false);
              setInteractionLoading(true);
              const isLike = value === 'like';
              setMessages((prev) => updateLikedStatus(prev, msg, 'pending'));
              try {
                await hostPlatformContext.apiFn<
                  ChatMessageFeedbackResponse,
                  ChatMessageFeedbackRequest
                >(`/chats/${msg.chatId}/messages/${msg.messageId}/feedbacks`, {
                  isHelpful: isLike,
                  comment: args?.text,
                });
                setMessages((prev) => updateLikedStatus(prev, msg, isLike));
                toast({
                  title: isLike ? 'Liked!' : 'Disliked!',
                  status: 'success',
                  description: 'Thanks for your feedback',
                  isClosable: true,
                  duration: 15000,
                });
              } catch (error) {
                setMessages((prev) => updateLikedStatus(prev, msg, undefined));
                throw error;
              } finally {
                setInteractionLoading(false);
              }
            }
            break;
          case 'copy':
            {
              setNeedToScrollToBottom(false);
              setInteractionLoading(true);
              try {
                const el = document.querySelector<HTMLElement>(
                  `[data-message-id="${msg.messageId}"] .worknet-markdown`
                );
                if (hostPlatformContext.featureOverrides?.copyToClipboard) {
                  await hostPlatformContext.featureOverrides.copyToClipboard?.(el!, msg.content);
                } else {
                  await copyToClipboard(el!, msg.content);
                }
                toast({
                  title: 'Copied!',
                  status: 'success',
                  description: 'Thanks message was copied',
                  isClosable: false,
                  duration: 500,
                });
              } finally {
                setInteractionLoading(false);
                // TODO: route not implemented yet
                // await hostPlatformContext.apiFn(`/track_events/${msg.messageId}/`, {
                //   eventType: 'copy_answer',
                // });
              }
            }
            break;
        }
      } else {
        //console.log("unsupported yet " + value);
        window.open(value, '_blank');
      }
    } catch (error) {
      toast({
        title: 'Error',
        status: 'error',
        description: (error as Error).message,
        isClosable: true,
      });
    }
  };

  const getCitationsButtons = (msg: AgentChatMessage, index?: number) => {
    const citationsButtonsData = getCitationsButtonsData(msg?.citations, msg?.sources);
    const allCitationButtons =
      citationsButtonsData?.map((buttonsData, idx) => (
        <CitationsButtons key={idx} buttonsData={buttonsData} />
      )) || [];

    return <Box as="span">{allCitationButtons[index ?? 0] || null}</Box>;
  };

  const firstMessage = initialMessages?.at(0);
  const isAllowInteraction =
    !initialMessages.length ||
    (firstMessage && 'user' in firstMessage && firstMessage.user?.email === user?.email);

  return (
    <>
      <Stack h="100%" pointerEvents={loading ? 'none' : 'auto'}>
        {messages.length === 0 && (
          <Box flex={1} animation={isNewChat ? `${fadeIn} 0.6s` : ''}>
            <DefaultMessage
              chatSettings={chatSettings}
              actions={actions}
              onSubmit={onAutoPromptSubmit}
              user={auth0User}
            />
          </Box>
        )}
        <Stack
          gap="20px"
          flex={1}
          overflowY="auto"
          transition="opacity 0.6s"
          opacity={messagesOpacity}
        >
          {messages.map((msg, i) => {
            const isAgent = msg.role === 'agent';
            return (
              <MessageContainer
                key={i}
                createdAt={msg.createdAt}
                isAgent={isAgent}
                chatSettings={chatSettings}
                name={isAgent ? undefined : msg.user?.name}
                imageSrc={isAgent ? undefined : msg.user?.picture}
              >
                {isAgent ? (
                  <ServerMessage
                    hideButtons={i !== messages.length - 1 && isAllowInteraction}
                    messageId={msg.messageId}
                    message={formatMessageWithHtml({
                      content: msg.content,
                      paragraphs: msg.citations?.map(({ paragraph }) => paragraph),
                    })}
                    components={{
                      span: ({ index }) => getCitationsButtons(msg, index),
                    }}
                    liked={typeof msg.liked === 'boolean' ? msg.liked : undefined}
                    loading={interactionLoading}
                    onSubmit={(type, value, args) => onServerMessageCommand(type, value, msg, args)}
                    tagGroups={createTagGroups(msg.sources)}
                  />
                ) : (
                  <UserMessage
                    key={i}
                    isSameUser={msg.user?.email === user?.email}
                    message={msg.content}
                  />
                )}
              </MessageContainer>
            );
          })}
          {loading && (
            <MessageContainer createdAt={new Date()} isAgent chatSettings={chatSettings}>
              <ServerLoadingMessage message={streaming?.value || ''} />
            </MessageContainer>
          )}
          <ScrollPoint active={needToScrollToBottom} />
        </Stack>

        {isAllowInteraction && (
          <Stack mt="auto">
            {/* {messages.length > 0 && (
              <AutoPromptArea prompts={actions} variant="sm" onSubmit={onAutoPromptSubmit} />
            )} */}
            {amaAction.userInputRequired && (
              <UserInput submitDisabled={false} onSubmit={onUserInputSubmit} />
            )}
          </Stack>
        )}
      </Stack>
      {!!modalConfig && <TextboxModal onClose={onTextboxModalClose} {...modalConfig} />}
      {!!confirmationModalConfig && <ConfirmationModal {...confirmationModalConfig} />}
    </>
  );
}
