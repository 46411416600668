import {
  MenuButton as ChakraMenuButton,
  Menu as ChakraMenu,
  MenuListProps,
  MenuList,
  Box,
  MenuItem,
  Flex,
  Icon,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface MenuProps extends MenuListProps {
  MenuButton: ReactNode;
  items: {
    text: string;
    IconSvg?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    onClick?: () => void;
    isDisabled?: boolean;
  }[];
}
export default function Menu({ items, MenuButton, ...rest }: MenuProps) {
  return (
    <ChakraMenu>
      <ChakraMenuButton role="group">{MenuButton}</ChakraMenuButton>
      <MenuList {...rest}>
        {items.map(({ text, IconSvg, onClick, isDisabled }) => (
          <Box key={text} role="group">
            <MenuItem onClick={onClick} isDisabled={isDisabled}>
              <Flex gap="6px" align="center">
                {IconSvg && (
                  <Icon
                    _groupHover={isDisabled ? {} : { strokeWidth: '2px' }}
                    strokeWidth={1.2}
                    boxSize="19px"
                    stroke="currentcolor"
                    as={IconSvg}
                  />
                )}
                {text}
              </Flex>
            </MenuItem>
          </Box>
        ))}
      </MenuList>
    </ChakraMenu>
  );
}
