import { theme as chakraTheme, extendTheme } from '@chakra-ui/react';
import { css } from '@emotion/css';
import { rgba, darken, lighten } from 'polished';

const font = `Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`;

const url = new URL(window.location.href);
const params = new URLSearchParams(url.search);
const customPrimaryColor = params.get('primaryColor');

const extendedConfig = {
  ...chakraTheme,
  fonts: {
    ...chakraTheme.fonts,
    body: font,
    heading: font,
  },
  colors: {
    ...chakraTheme.colors,
    main: customPrimaryColor || '#587AFF',
    secondary: `${rgba(customPrimaryColor || '#587AFF', 0.11)}`,
    inputStroke: '#A0AEC0',
    buttonStroke: '#D5D8DA',
    background: '#FFFFFF',
    error: 'red',
    primaryDark: customPrimaryColor ? `${darken(0.37, customPrimaryColor)}` : '#001F97',
    primaryLight: customPrimaryColor ? `${lighten(0.29, customPrimaryColor)}` : '#EDF1FF',
    greyNatural: '#828282',
    greyDark: '#718096',
  },
  fontSizes: {
    ...chakraTheme.fontSizes,
    title: '16px',
    chatText: '14px',
    chatSmallText: '12px',
    chatTinyText: '10px',
  },
  fontWeights: {
    ...chakraTheme.fontWeights,
    normal: 400,
    medium: 600,
    bold: 800,
  },
  components: {
    Button: {
      variants: {
        primary: {
          bg: 'main',
          color: 'white',
          fontSize: 'chatSmallText',
          fontWeight: '700',
          borderRadius: '8px',
          px: '12px',
          py: '8px',
          _hover: { bg: 'primaryDark' },
          _active: { bg: 'primaryDark' },
          _disabled: { bg: '#E3E3E3 !important', color: '#828282 !important' },
        },
      },
    },
    Menu: {
      baseStyle: {
        list: { py: '12px' },
        item: {
          color: 'black',
          fontSize: '15px',
          bg: 'none',
          px: '12px',
          _hover: { color: 'main', fontWeight: 700, transition: '0.1s' },
          _disabled: { _hover: { color: 'black' }, fontWeight: '300 !important' },
        },
      },
    },
  },
};
export const theme = extendTheme(extendedConfig);

export const markdownStyles = css`
  &:has(img) {
    width: 100%;
  }
  word-wrap: break-word; /* Older syntax, supported in most browsers */
  overflow-wrap: break-word; /* Standard syntax */
`;
