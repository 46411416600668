import { Button, Icon, Input, InputGroup, InputRightElement, useToast } from '@chakra-ui/react';
import { useState } from 'react';

import { ReactComponent as SendSvg } from './assets/Send.svg';

export default function UserInput({
  onSubmit,
  submitDisabled,
}: {
  submitDisabled: boolean;
  onSubmit: (text: string) => void;
}) {
  const [text, setText] = useState('');
  const disabled = submitDisabled || !text.trim();
  const toast = useToast();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (disabled) {
          toast({
            title: 'Error',
            status: 'error',
            description: 'Please enter text before submitting',
            isClosable: true,
          });
          return;
        }
        onSubmit(text);
        setText('');
      }}
    >
      <InputGroup>
        <Input
          fontSize="chatText"
          placeholder="Message Worknet..."
          borderRadius="5px"
          border="none"
          outline="1px solid"
          outlineColor="greyNatural"
          _focus={{
            outline: '2px solid',
            outlineColor: 'main',
          }}
          _placeholder={{
            color: 'greyDark',
          }}
          color={'black'}
          value={text}
          onChange={(e) => setText(e.target?.value)}
        />
        <InputRightElement h="full" border="none">
          <Button
            h="full"
            fontSize="chatText"
            borderRadius="0px"
            type="submit"
            variant="link"
            disabled={disabled}
            pointerEvents={'inherit'}
            backgroundColor={'main'}
          >
            <Icon as={SendSvg} ml={'2px'} w="16px" h="16px" fill={'white'} />
          </Button>
        </InputRightElement>
      </InputGroup>
    </form>
  );
}
