import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import HostedApp from '~/shared/components/HostedApp';
import { HostPlatformContext } from '~/shared/utils/api';
import { useAuth0ApiFn } from '~/shared/utils/useAuth0ApiFn.ts';

import { useZendeskBusinessContext } from '../utils';

export default function ZendeskApp() {
  const toast = useToast();
  const { user } = useAuth0();
  const { error, value } = useZendeskBusinessContext();
  const apiFn = useAuth0ApiFn(value ? { 'x-wn-zendesk-domain': value.hostname } : {});
  const hostPlatformContext: HostPlatformContext | undefined = value
    ? {
        sourceContext: value.sourceContext,
        apiFn,
        user,
        groupingId: `${value.hostname}/${value.sourceContext.ticketId}`,
      }
    : undefined;
  useEffect(() => {
    if (error) {
      toast({
        title: 'Error',
        status: 'error',
        description: error.message,
        isClosable: true,
      });
    }
  }, [error, toast]);

  useEffect(() => {
    if (!value) {
      return;
    }
    apiFn('/chat-context/preload', value.sourceContext).catch(console.error);
  }, [apiFn, value]);

  return <HostedApp hostedPlatformContext={hostPlatformContext} />;
}
