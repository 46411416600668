import { AnswerSource, Citation } from '@worknet/models';

import { AgentChatMessage, ChatMessage } from './Messages';

function getDomainFromUrl(url: string) {
  try {
    return new URL(url).hostname;
  } catch (error) {
    console.error('Invalid URL:', error);
    return '';
  }
}

export function updateLikedStatus(
  messages: ChatMessage[],
  message: AgentChatMessage,
  liked: AgentChatMessage['liked']
) {
  return messages.map((m) => {
    if (m.role === 'agent' && m.messageId == message.messageId) {
      return { ...m, liked };
    }
    return m;
  });
}

export function getCitationsButtonsData(
  citationsWithParagraph?: Citation[],
  sources?: AnswerSource[]
) {
  return (
    citationsWithParagraph?.map(({ citations }) => {
      const buttonsData =
        citations?.map(({ citation, source_idx }) => {
          const source = sources?.[source_idx];
          return {
            text: (source_idx + 1).toString(),
            onClick: () => source?.url && window.open(source?.url, '_blank'),
            toolTip: {
              title: getDomainFromUrl(source?.url || ''),
              subTitle: source?.title || '',
              body: `"${citation}"`,
            },
          };
        }) || [];

      return buttonsData;
    }) || []
  );
}

export function formatMessageWithHtml({
  content,
  paragraphs,
}: {
  content: string;
  paragraphs?: string[];
}): string {
  if (!paragraphs) {
    return content;
  }

  let result = content;
  const addedParagraphs = new Set<string>();
  paragraphs.forEach((paragraph, index) => {
    if (addedParagraphs.has(paragraph)) {
      return;
    }
    addedParagraphs.add(paragraph);
    result = result.replaceAll(paragraph, `${paragraph}<span index=${index}></span>`);
  });
  return result;
}
