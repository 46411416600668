import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Textarea,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { useState } from 'react';

export default function TextboxModal<Data>({
  required,
  button,
  title,
  placeholder,
  onClose,
  data,
}: {
  required?: boolean;
  button: string;
  title: string;
  placeholder: string;
  onClose: (data: Data, text: string | undefined) => void;
  data: Data;
}) {
  const { isOpen, onClose: closeModal } = useDisclosure({ isOpen: true });
  const [input, setInput] = useState('');

  function onModalFinished(ok?: boolean) {
    closeModal();
    onClose(data, ok ? input : undefined);
  }

  return (
    <Modal isOpen={isOpen} onClose={onModalFinished}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Textarea
            required={required}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            rows={3}
            placeholder={placeholder}
          />
        </ModalBody>
        <ModalFooter>
          <Button background="main" color="white" onClick={() => onModalFinished(true)}>
            {button}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
