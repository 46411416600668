import { lazy } from 'react';
import { Route, Navigate, Routes as _Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import BouncingLoader from '~/shared/components/ds/BouncingLoader';
import MainFlowLayout from '~/shared/components/ds/MainFlowLlayout';
import Zendesk from '~/zendesk'; // resize for auth screen not working with `lazy(() => import('~/zendesk'));`

import AuthenticatedOutlet from './AuthenticatedOutlet';

const Salesforce = lazy(() => import('~/salesforce'));
const Iframe = lazy(() => import('~/iframe'));
const AnonIframe = lazy(() => import('~/anonIframe'));

export default function Routes() {
  const { isLoading } = useAuth0();

  const defaultNav = sessionStorage.getItem('__last_pathname__') || '/anon-iframe';

  if (isLoading) {
    return (
      <MainFlowLayout>
        <BouncingLoader />
      </MainFlowLayout>
    );
  }

  return (
    <>
      <_Routes>
        <Route index element={<Navigate to={defaultNav} />} />
        <Route path="/anon-iframe" element={<AnonIframe />} />
        <Route path="/" Component={() => <AuthenticatedOutlet />}>
          <Route path="zendesk" element={<Zendesk />} />
          <Route path="salesforce" element={<Salesforce />} />
          <Route path="iframe" element={<Iframe />} />
        </Route>
        <Route path="/*" Component={() => <div>Not Found</div>} />
      </_Routes>
    </>
  );
}
