import { Button, Icon, Stack, Text, Box } from '@chakra-ui/react';
import { ChatSession, ChatSessionsResponse } from '@worknet/models';
import { useMemo } from 'react';

import { ReactComponent as PlusIcon } from '~/shared/components/ds2/assets/Plus.svg';

import { getFormattedDate } from '../utils';

import SessionCard from './SessionCard';

export type SessionsEvent = { name: 'openChat'; sessionsId?: string };
interface SessionsProps {
  sessions: ChatSessionsResponse;
  onSessionsEvent: (evt: SessionsEvent) => void;
}

export default function Sessions({ sessions, onSessionsEvent }: SessionsProps) {
  const sessionsByDay = useMemo(() => getSessionsByDay(sessions), [sessions]);

  return (
    <Stack flex={1} overflowY="hidden">
      <Stack gap="20px" overflowY="auto">
        {sessionsByDay.map((chats) => {
          const date = getFormattedDate(new Date(chats.date));
          return (
            <Box key={date}>
              <Text
                fontSize="chatSmallText"
                mb="8px"
                ml="59px"
                color="greyNatural"
                fontWeight={600}
              >
                {date}
              </Text>

              {chats.sessions.map((session, index) => (
                <SessionCard
                  key={date + index}
                  session={session}
                  onClick={() =>
                    onSessionsEvent({ name: 'openChat', sessionsId: session.sessionId })
                  }
                />
              ))}
            </Box>
          );
        })}
      </Stack>

      <Button
        variant="primary"
        mt="auto"
        mx="21px"
        borderRadius="4px"
        gap="5px"
        flexShrink={0}
        onClick={() => onSessionsEvent({ name: 'openChat', sessionsId: '' })}
      >
        <Icon boxSize="12px" fill="white" as={PlusIcon} />
        Send a message
      </Button>
    </Stack>
  );
}

function getSessionsByDay(sessions: ChatSessionsResponse) {
  const sessionsByDayMap = sessions.reduce<Record<string, ChatSession[]>>((acc, session) => {
    const lastMessageDate = new Date(session.lastMessageSentAt);
    const startOfDay = new Date(
      lastMessageDate.getFullYear(),
      lastMessageDate.getMonth(),
      lastMessageDate.getDate()
    );
    const day = startOfDay.toISOString();
    acc[day] ??= [];
    acc[day].push(session);
    return acc;
  }, {});

  const sessionsByDay = Object.keys(sessionsByDayMap)
    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
    .map((day) => ({
      date: day,
      sessions: sessionsByDayMap[day],
    }));
  return sessionsByDay;
}
