import { Flex, Image, Text, FlexProps, Stack, Icon, Center, Box } from '@chakra-ui/react';
import { ChatSettings } from '@worknet/models';

import { ReactComponent as LogoIcon } from '~/shared/components/ds2/assets/LogoLetter.svg';
import { ReactComponent as GuestIcon } from '~/shared/components/ds2/assets/Guest.svg';

interface MessageContainerProps extends FlexProps {
  imageSrc?: string;
  name?: string;
  createdAt: Date;
  isAgent?: boolean;
  chatSettings?: ChatSettings;
}
export default function MessageContainer({
  createdAt,
  imageSrc,
  chatSettings,
  name,
  isAgent,
  children,
}: MessageContainerProps) {
  return (
    <Flex gap="10px" w="100%">
      <Box boxSize="32px" flexShrink={0} borderRadius="4px" overflow="hidden" mt="4px">
        {isAgent ? (
          chatSettings?.chatBotImageUrl ? (
            <Image src={chatSettings.chatBotImageUrl} h="full" />
          ) : (
            <Center bg="main" h="full">
              <Icon as={LogoIcon} stroke="white" boxSize="17px" />
            </Center>
          )
        ) : imageSrc ? (
          <Image src={imageSrc} h="full" />
        ) : (
          <Center bg="main" h="full">
            <Icon as={GuestIcon} fill="white" boxSize="18px" />
          </Center>
        )}
      </Box>

      <Stack w="100%" gap="4px">
        <Flex align="center" gap="10px" ml="1px">
          <Text fontWeight="bold" fontSize="chatText">
            {isAgent ? chatSettings?.chatBotName || 'Ai assistant' : name || 'Guest'}
          </Text>
          <Text fontSize="chatTinyText" color="greyNatural" mt="2px">
            {new Date(createdAt).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </Text>
        </Flex>

        {children}
      </Stack>
    </Flex>
  );
}
