import { useAuth0 } from '@auth0/auth0-react';
import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';

export default function useErrorHandler(error?: Error) {
  const { logout } = useAuth0();
  const toast = useToast();

  useEffect(() => {
    if (error) {
      error.message === 'Unauthorized'
        ? logout({
            openUrl() {
              window.location.assign(`${window.location.href}&error=Unauthorized`);
            },
          })
        : toast({
            title: 'Error',
            status: 'error',
            description: error.message,
            isClosable: true,
          });
    }
  }, [error, logout, toast]);
}
