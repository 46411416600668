import { Box, Center, Flex, Icon, Stack, Text, VStack } from '@chakra-ui/react';
import { ComponentProps, useState } from 'react';

import { markdownStyles } from '~/shared/utils/theme';

import Markdown, { MarkdownProps } from './Markdown';
import { ReactComponent as CopySvg } from './assets/IoCopySharp.svg';
import { ReactComponent as ThumbsDownSvg } from './assets/MdThumbDownAlt.svg';
import { ReactComponent as ThumbsUpSvg } from './assets/MdThumbUpAlt.svg';
import TextboxModal from './TextboxModal';

type TagGroup = {
  title: string;
  tags: { title: string; content?: string }[];
  isLink?: boolean;
};

type Props = {
  messageId?: string;
  message: string;
  hideButtons?: boolean;
  components?: MarkdownProps['components'];
  liked?: boolean;
  loading?: boolean;
  tagGroups?: TagGroup[];
  showAvatar?: boolean;
  onSubmit?: (type: 'button' | 'tag', value: string, args: Record<string, string>) => void;
};

function TagGroups({ tagGroups, onSubmit }: Pick<Props, 'tagGroups' | 'onSubmit'>) {
  return (
    <>
      {tagGroups
        ?.filter?.(({ tags }) => !!tags?.length)
        .map?.(({ title, tags, isLink }, i) => (
          <VStack key={i} alignItems="start" marginTop="1">
            <Text fontSize="chatTinyText" fontWeight="bold">
              {title}:
            </Text>
            <Flex flexWrap="wrap" gap="11px">
              {tags.map((t, i) => (
                <Center
                  h="24px"
                  px="8px"
                  bg="white"
                  borderRadius="4px"
                  border="1px"
                  borderColor="greyNatural"
                  cursor="pointer"
                  transition="0.15s"
                  _hover={{ bg: '#B8B8B8' }}
                  key={i}
                  onClick={isLink ? () => onSubmit?.('tag', t.content!, {}) : undefined}
                >
                  <Text fontSize="chatTinyText" fontWeight="bold" maxW="40ch" isTruncated>
                    {t.title}
                  </Text>
                </Center>
              ))}
            </Flex>
          </VStack>
        ))}
    </>
  );
}

function IconButton({
  title,
  svg,
  onClick,
  active,
  disabled,
}: {
  title: string;
  svg: typeof ThumbsUpSvg;
  onClick?: ComponentProps<typeof Box>['onClick'];
  active?: boolean;
  disabled?: boolean;
}) {
  const isDisabled = disabled || active;

  return (
    <Box
      title={title}
      fontSize="title"
      lineHeight="16px"
      as="button"
      color={active ? 'main' : '#545F715C'}
      _hover={isDisabled ? {} : { color: '#545F7199' }}
      border={'none'}
      onClick={isDisabled ? null : onClick}
      cursor={isDisabled ? 'default !important' : 'pointer'}
    >
      <Icon as={svg} boxSize="15px" />
    </Box>
  );
}

const modalConfigs = {
  iterate: {
    title: 'Refine the generated response',
    placeholder: `Provide guidance like: "Shorten it and make it friendly"`,
    button: 'Refine',
    required: true,
    data: 'iterate',
  },
  like: {
    title: 'Mark this answer as helpful',
    placeholder: 'Additional textual feedback (optional)',
    button: 'Send feedback',
    data: 'like',
  },
  dislike: {
    title: 'Mark this answer as not helpful',
    placeholder: 'Additional textual feedback (optional)',
    button: 'Send feedback',
    data: 'dislike',
  },
};

function Buttons({
  loading,
  liked,
  disliked,
  onSubmit,
}: {
  loading?: boolean;
  liked?: boolean;
  disliked?: boolean;
  onSubmit: Props['onSubmit'];
}) {
  const disabled = loading || liked || disliked;
  const [modalAction, setModalAction] = useState<string>();
  const modalConfig = modalConfigs[modalAction as keyof typeof modalConfigs];

  function onTextboxModalClose(_: string, text: string | undefined) {
    setModalAction(undefined);
    const isCancel = text === undefined;
    if (isCancel) {
      return;
    }
    onSubmit?.('button', modalAction!, { text });
  }

  return (
    <>
      <Flex display={'flex'} justifyContent="end" gap="6px">
        <IconButton
          active={liked}
          disabled={disabled}
          svg={ThumbsUpSvg}
          title="Like"
          onClick={() => {
            setModalAction('like');
          }}
        />
        <IconButton
          active={disliked}
          disabled={disabled}
          svg={ThumbsDownSvg}
          title="Dislike"
          onClick={() => {
            setModalAction('dislike');
          }}
        />
        <IconButton
          disabled={loading}
          svg={CopySvg}
          title="Copy to clipboard"
          onClick={() => onSubmit?.('button', 'copy', {})}
        />
      </Flex>
      {!!modalConfig && <TextboxModal onClose={onTextboxModalClose} {...modalConfig} />}
    </>
  );
}

export default function ServerMessage({
  messageId,
  message,
  tagGroups,
  components,
  hideButtons,
  liked,
  loading,
  onSubmit,
}: Props) {
  return (
    <Stack
      data-message-id={messageId}
      width="100%"
      fontSize="chatText"
      alignSelf="self-start"
      gap="9px"
      className={markdownStyles}
    >
      <Stack bg="#F2F2F2" color="black" borderRadius={8} p="10px 16px" gap={2} flex="1">
        <Box pos="relative">
          <Markdown content={message} components={components} />
        </Box>
        <TagGroups tagGroups={tagGroups} onSubmit={onSubmit} />
      </Stack>
      {!hideButtons && (
        <Buttons
          liked={liked}
          loading={loading}
          disliked={typeof liked === 'boolean' && !liked}
          onSubmit={onSubmit}
        />
      )}
    </Stack>
  );
}
