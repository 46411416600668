import { Flex, FlexProps, Image, ImageProps } from '@chakra-ui/react';

interface UsersImagesProps extends FlexProps {
  imagesSrc: string[];
  imageProps?: ImageProps;
}

export default function UsersImages({ imagesSrc, imageProps, ...rest }: UsersImagesProps) {
  return (
    <Flex pos="relative" gap="3px" flexShrink={0} {...rest}>
      {imagesSrc.map((src, index, arr) => {
        return (
          <Image
            key={src + index}
            src={src}
            boxSize="22px"
            pos="relative"
            zIndex={-index}
            right={index !== 0 ? 3 * index : 0}
            pr={index !== arr.length - 1 ? '2px' : '0px'}
            bg="white"
            borderRadius="4px"
            {...imageProps}
          />
        );
      })}
    </Flex>
  );
}
