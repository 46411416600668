import { ReactNode } from 'react';
import { ChakraProvider } from '@chakra-ui/react';

import { theme } from '../utils/theme';

type Props = { children: ReactNode };

export default function ThemedChakraProvider({ children }: Props) {
  return (
    <ChakraProvider theme={theme} toastOptions={{ defaultOptions: { position: 'top-right' } }}>
      {children}
    </ChakraProvider>
  );
}
