import { User } from '@auth0/auth0-react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { ActionDisplayInfo, ChatSettings } from '@worknet/models';
import { template } from 'lodash';

import Markdown from '~/shared/components/ds/Markdown';

interface DefaultMessageProps {
  actions: Array<ActionDisplayInfo>;
  onSubmit: (action: ActionDisplayInfo) => void;
  user?: User;
  chatSettings?: ChatSettings;
}
const defaultWelcomeMessage = `**Hi {{USER}},**
I'm here to help you. Whether it's guidance or answering questions, feel free to reach out.`;

export default function DefaultMessage({
  actions,
  onSubmit,
  user,
  chatSettings,
}: DefaultMessageProps) {
  return (
    <Stack color="black" gap="29px" m="auto" mb="40px" h="full" justifyContent="center">
      <Box fontSize="chatText" whiteSpace="pre-wrap">
        <Markdown
          content={formatDefaultMessage(
            chatSettings?.chatWelcomeMessage || defaultWelcomeMessage,
            user
          )}
        />
      </Box>

      <Stack gap="1px">
        {actions.map((p, i) => (
          <Stack
            key={p.displayName}
            h="50px"
            gap="2px"
            bg="primaryLight"
            py="9px"
            px="16px"
            cursor="pointer"
            onClick={() => onSubmit(p)}
            borderTopRadius={i === 0 ? '4px' : undefined}
            borderBottomRadius={i === actions.length - 1 ? '4px' : undefined}
            _active={{ bg: 'main', color: 'white', transition: '0.1s' }}
            isTruncated
          >
            <Text fontSize="chatSmallText" fontWeight="bold" isTruncated>
              {p.displayName}
            </Text>
            <Text fontSize="chatTinyText" isTruncated>
              {p?.description || ''}
            </Text>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

const trimInAsterisksPattern = /(\*{2,3})\s*(.*?)\s*\1/g;

function formatDefaultMessage(message: string, user?: User): string {
  try {
    return template(message, { interpolate: /\{\{(.+?)\}\}/gim })({
      USER: user?.given_name || '',
    }).replace(trimInAsterisksPattern, '$1$2$1');
  } catch (err) {
    console.log(err);
    return message.replace(trimInAsterisksPattern, '$1$2$1');
  }
}
