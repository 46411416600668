import { css } from '@emotion/css';
import MarkdownRenderer, { Components } from 'react-markdown';
import gfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import emoji from 'emoji-dictionary';
import { ReactNode } from 'react';

const markdownStyles = css`
  & p:has(img) {
    margin: -16px -16px 0;
  }

  & img {
    width: 100%;
    border-radius: 8px 8px 0 0;
  }

  & ol li,
  & ul li {
    list-style-position: inside;
  }

  & h2 {
    font-size: 16px;
  }

  & h4 {
    font-size: 12px;
  }
`;

export interface MarkdownProps {
  content: string;
  spanCustomComponents?: ReactNode[];
  components?: Components;
}

export default function Markdown({ content, components }: MarkdownProps) {
  const fixedMarkdown = content.trim().replaceAll(/\n\n/g, '\n\n&nbsp;\n\n');
  const emojiSupport = (text: { value: string }) =>
    text.value.replace(/:\w+:/gi, (name: string) => emoji.getUnicode(name));

  return (
    <MarkdownRenderer
      skipHtml
      className={`${markdownStyles} worknet-markdown`}
      remarkPlugins={[gfm]}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      rehypePlugins={[rehypeRaw as any]}
      components={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        text: emojiSupport as any,
        ...components,
      }}
    >
      {fixedMarkdown}
    </MarkdownRenderer>
  );
}
