export async function copyToClipboard(element: HTMLElement, markdown?: string) {
  const htmlBlob = new Blob([element.outerHTML], { type: 'text/html' });
  const textBlob = new Blob([markdown || element.innerText], { type: 'text/plain' });
  const data = [
    new ClipboardItem({
      'text/html': htmlBlob,
      'text/plain': textBlob,
    }),
  ];
  await navigator.clipboard.write(data);
}
