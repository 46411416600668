import { PropsWithChildren, StrictMode } from 'react';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { Auth0Provider, AppState, Auth0ProviderOptions, User } from '@auth0/auth0-react';
import { datadogLogs } from '@datadog/browser-logs';

import ThemedChakraProvider from '~/shared/components/ThemedChakraProvider';
import { runtimeEnv } from '~/shared/utils/runtimeEnv';

import Routes from './Routes';

const Auth0ProviderWithRedirectCallback = ({
  children,
  ...props
}: PropsWithChildren<Auth0ProviderOptions>) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState, user?: User) => {
    if (user) {
      datadogLogs.setUser({
        id: user.id,
        name: user.name as string,
        email: user.email as string,
        orgName: user.org_name, // TODO: check if this is sent
      });

      console.error('my test error');
    }

    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

if (!import.meta.env.DEV) {
  datadogLogs.init({
    clientToken: 'pub55f807e3bc1ef8aa7f284f0ec35c75cc',
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    // env: runtimeEnv.stage,
    // version: runtimeEnv.version,
    forwardConsoleLogs: ['error', 'warn'],
    service: `copilot-site`,
    beforeSend(event) {
      event['env'] = runtimeEnv.stage;
      event['version'] = runtimeEnv.version;
      return true;
    },
  });
}

export default function App() {
  return (
    <StrictMode>
      <ThemedChakraProvider>
        <BrowserRouter>
          <Auth0ProviderWithRedirectCallback
            domain={runtimeEnv.auth0Domain}
            clientId={runtimeEnv.auth0ClientId}
            authorizationParams={{
              audience: runtimeEnv.auth0Audience,
              redirect_uri: window.location.origin,
            }}
          >
            <Routes />
          </Auth0ProviderWithRedirectCallback>
        </BrowserRouter>
      </ThemedChakraProvider>
    </StrictMode>
  );
}
