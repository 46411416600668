import {
  EventSourceMessage,
  FetchEventSourceInit,
  fetchEventSource,
} from '@microsoft/fetch-event-source';

import { getDummyToken } from './getDummyToken';

export function createFetchApiFn(
  baseUrl: string,
  getToken: () => Promise<string>,
  authHeaders: Record<string, string>
) {
  return async function apiFn<TRes = unknown, TReq = unknown>(path: string, body?: TReq) {
    const url = baseUrl + path;
    const token = await getToken();
    const options: RequestInit = {
      ...(body ? { method: 'POST', body: JSON.stringify(body) } : { method: 'GET' }),
      ...(import.meta.env.DEV ? {} : { mode: 'cors', credentials: 'include' }),
      headers: {
        ...(body ? { 'content-type': 'application/json' } : {}),
        ...(token ? { authorization: `Bearer ${token}` } : {}),
        ...authHeaders,
      },
    };
    console.log({ url, options });
    const res = await fetch(url, options);
    const json = await res.json();

    if (!res.ok) {
      console.error({ res, json });
      throw new Error(res.statusText);
    }

    return json as TRes;
  };
}

export function createStreamingApiFn(baseUrl: string, getToken: () => Promise<string>) {
  return async function apiFn<TReq = unknown>(
    path: string,
    onMessage: (event: EventSourceMessage) => void,
    body?: TReq
  ) {
    const url = baseUrl + path;
    const token = await getToken();
    const options: FetchEventSourceInit = {
      ...(body ? { method: 'POST', body: JSON.stringify(body) } : { method: 'GET' }),
      ...(import.meta.env.DEV ? {} : { mode: 'cors', credentials: 'include' }),
      headers: {
        ...(body ? { 'content-type': 'application/json' } : {}),
        ...(token ? { authorization: `Bearer ${token}` } : {}),
      },
      onmessage: onMessage,
      onerror(err) {
        throw new Error(err);
      },
    };
    await fetchEventSource(url, options);
  };
}

export function createDevModeFetchApiFn(authHeaders: Record<string, string>) {
  return createFetchApiFn('/v1', getDummyToken, authHeaders);
}
