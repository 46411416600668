import { Flex, Stack, Image, Text, useTheme } from '@chakra-ui/react';
import { ChatSession } from '@worknet/models';
import { lighten } from 'polished';

import { getTimeInDay } from '../utils';

interface SessionCardProps {
  onClick: () => void;
  session: ChatSession;
}

export default function SessionCard({ onClick, session }: SessionCardProps) {
  const { colors } = useTheme();
  const users = Object.values(session.users);
  const firstUser = users.at(0);
  //images for now
  // const displayedUsersImages = users.map((user) => user.picture);
  return (
    <Flex
      h="81px"
      px="21px"
      py="8px"
      cursor="pointer"
      alignItems="start"
      transition="0.2s"
      justifyContent="space-between"
      _hover={{ bg: `${lighten(0.287, colors.main)}` }}
      _active={{ bg: `${lighten(0.225, colors.main)}` }}
      onClick={onClick}
      color="black"
    >
      <Flex gap="10px" w="full">
        <Image mt="3px" boxSize="28px" borderRadius="4px" src={firstUser?.picture} />

        <Stack gap="3px" w="full">
          <Flex alignItems="center" gap="10px" flex={1}>
            <Text fontWeight="bold" fontSize="chatText">
              {firstUser?.name}
            </Text>
            <Text fontSize="chatTinyText" color="greyNatural">
              {getTimeInDay(new Date(session.lastMessageSentAt))}
            </Text>

            {/* <Flex ml="auto" gap="5px" zIndex={0} align="center">
              <UsersImages
                imagesSrc={displayedUsersImages}
                left={(displayedUsersImages.length - 1) * 3}
                imageProps={{ boxSize: '18px' }}
              />
              <Text color="greyNatural" fontSize="chatTinyText">
                {displayedUsersImages.length}
              </Text>
            </Flex> */}
          </Flex>

          <Text fontSize="chatText" noOfLines={2} wordBreak="break-word">
            {session.summary}
          </Text>
        </Stack>
      </Flex>
    </Flex>
  );
}
