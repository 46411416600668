import { Client } from './types';

// eslint-disable-next-line no-var
declare var ZAFClient: {
  init: () => Client;
};

export const client = ZAFClient?.init();

client?.invoke?.('resize', { width: '100%', height: '600px' });
