// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const runtimeEnv = (window as any).__RUNTIME_ENV__ as {
  version: string;

  stage: string;

  auth0Domain: string;
  auth0ClientId: string;
  auth0Audience: string;

  apiBaseUrl: string;
  streamingBaseUrl: string;
};

export default runtimeEnv;
